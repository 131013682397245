include ../../../../common/primary/select/select.mixin.pug

form.fp-vertical-spacing.-s-xl(v-if='!auctions.isLoading()' autocomplete='on' @submit.prevent='confirm' @drop.prevent='onDrop' @dragover.prevent='onDragOver' data-test='exclusive.form')
  .fp-vertical-spacing--line
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-lg
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-info.-s16
            .fp-horizontal-spacing--column
              .fp-text.-text-semi-bold.-text-lg {{ t('exclusiveForm.globalInformation') }}
        .fp-vertical-spacing--line(v-for='nameTranslation in exclusiveForm.nameTranslations' :key='nameTranslation.language')
          label.fp-field
            .fp-horizontal-spacing.-items-center.-s-sm(:draggable='true' @dragstart='onDragStart' data-test='draggable')
              .fp-horizontal-spacing--column
                | {{ t('exclusiveForm.name') }}
              .fp-horizontal-spacing--column
                img.fp-illustration.-xs(:class='`-flag-${nameTranslation.language}`' :data-test='`name-flag-${nameTranslation.language}`')
            .fp-horizontal-spacing--column
              .fp-field--actions
                template(v-if='nameTranslation.language !== "fr"')
                  i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                  i.fp-icon.fp-glyph-translate(v-else @click='translateName(nameTranslation.language)' :class='hasName ? "-pointer" : "-color-shade-400"' :title='t(\'exclusiveForm.translate\')' :data-test='`translate-name-${nameTranslation.language}`')
                i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetNameTranslation(nameTranslation.language)' :class='hasNameTranslationChanged(nameTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-name-${nameTranslation.language}`' :title='t(\'undo\')')
              input.fp-input-text(v-model='nameTranslation.value' required @focusout='onNameFocusOut(nameTranslation)' :class='{"-touched": isUpdating && hasNameTranslationChanged(nameTranslation)}' :data-test='`name-${nameTranslation.language}`')
        .fp-vertical-spacing--line
          label.fp-field(data-test='slug-label')
            | {{ t(`exclusiveForm.slugOn${isUpdating ? 'Update' : 'Create'}`) }}
            .fp-field--actions
              i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTextField("slug")' :class='hasFieldChanged("slug") ? "-pointer" : "-color-shade-400"' data-test='reset-slug' :title='t(\'undo\')')
            input.fp-input-text(v-model='exclusiveForm.slug' pattern='[a-z0-9]+(?:-[a-z0-9]+)*' required :class='{"-touched": isUpdating && hasFieldChanged("slug")}' data-test='slug')
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column(data-test='main-image')
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing
                    .fp-horizontal-spacing--column.-expand
                      label.fp-field(data-test='image-input') {{ t('exclusiveForm.mainImage') }}
                    .fp-horizontal-spacing--column
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetImageUrl' :class='hasFieldChanged("imageUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-image' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  SingleImageInputVue(:media='mainImage' :touched='isUpdating && hasFieldChanged("imageUrl")' :required='!isUpdating' @updated-media='media => updateImage(media.value)')
            .fp-horizontal-spacing--column(data-test='extra-medias')
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  .fp-horizontal-spacing
                    .fp-horizontal-spacing--column.-expand
                      label.fp-field(data-test='medias-input') {{ t('exclusiveForm.extraMedia') }}
                    .fp-horizontal-spacing--column
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetMedias' :class='hasMediasChanged() ? "-pointer" : "-color-shade-400"' data-test='reset-medias' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  MultipleMediasInputVue(:medias='exclusiveForm.medias' :touched='isUpdating && hasMediasChanged()' @updated-medias='medias => updateMedias(medias.value)')
        .fp-vertical-spacing--line
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              label.fp-field
                | {{ t('exclusiveForm.visibility') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetVisibleField' :class='hasFieldChanged("visible") ? "-pointer" : "-color-shade-400"' data-test='reset-visible' :title='t(\'undo\')')
            .fp-vertical-spacing--line
              .fp-checkable-element
                .fp-checkable-element--checkbox
                  input.fp-checkbox#visible-checkbox(type='checkbox' v-model='exclusiveForm.visible' :class='{"-touched": isUpdating && hasFieldChanged("visible")}' data-test='visible')
                .fp-checkable-element--label
                  label(for='visible-checkbox') {{ t('exclusiveForm.visible') }}
        .fp-vertical-spacing--line
          label.fp-field
            | {{ t('exclusiveForm.category') }}
            .fp-field--actions
              i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetCategoryField' :class='hasFieldChanged("category") ? "-pointer" : "-color-shade-400"' data-test='reset-category' :title='t(\'undo\')')
            +fp-select
              select(v-model='exclusiveForm.category' name='category' :class='{"-touched": isUpdating && hasFieldChanged("category")}' data-test='category')
                option(v-for='(category, index) in categories' :key='index' :value='category' v-text='`${t(\'exclusive.categories.\' + category)}`')
        .fp-vertical-spacing--line(v-for='descriptionTranslation in exclusiveForm.descriptionTranslations' :key='descriptionTranslation.language')
          .fp-horizontal-spacing.-equivalent
            .fp-horizontal-spacing--column
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  label.fp-field
                    .fp-horizontal-spacing.-items-center.-s-sm
                      .fp-horizontal-spacing--column
                        | {{ t('exclusiveForm.description') }}
                      .fp-horizontal-spacing--column
                        img.fp-illustration.-xs(:class='`-flag-${descriptionTranslation.language}`' :data-test='`description-flag-${descriptionTranslation.language}`')
                      .fp-field--actions
                        template(v-if='descriptionTranslation.language === "fr"')
                          i.fp-icon.fp-glyph-magic-wand.-pointer(@click='openDraftDescriptionSidebar()' :data-test='`request-draft-description-${descriptionTranslation.language}`' :title='t(\'completion.generate\')')
                        template(v-if='descriptionTranslation.language !== "fr"')
                          i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                          i.fp-icon.fp-glyph-translate(v-else @click='translateDescription(descriptionTranslation.language)' :class='hasDescription ? "-pointer" : "-color-shade-400"' :title='t(\'exclusiveForm.translate\')' :data-test='`translate-description-${descriptionTranslation.language}`')
                        i.fp-icon.-pointer(@click='togglePreviewFor(descriptionTranslation)' :class='previewVisibleFor(descriptionTranslation) ? "fp-glyph-eye-slash" : "fp-glyph-eye"' :data-test='`toggle-preview-${descriptionTranslation.language}`' :title='previewVisibleFor(descriptionTranslation) ? t("hidePreview") : t("showPreview")')
                        i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetDescriptionTranslation(descriptionTranslation.language)' :class='hasDescriptionTranslationChanged(descriptionTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-description-${descriptionTranslation.language}`' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  MarkdownEditorVue(:text='descriptionTranslation' @updated-value='text => updateTranslation(descriptionTranslation, text.value)' :data-test='`description-${descriptionTranslation.language}`' :touched='isUpdating && hasDescriptionTranslationChanged(descriptionTranslation)' :disabled='isTranslationLoading')
            .fp-horizontal-spacing--column(v-if='previewVisibleFor(descriptionTranslation)')
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  .fp-text.-text-semi-bold {{ t('exclusiveForm.preview') }}
                .fp-vertical-spacing--line
                  .fp-text.-text-sm(v-html='formatDescription(descriptionTranslation.value)' :data-test='`preview-description-${descriptionTranslation.language}`')
  .fp-vertical-spacing--line
    .fp-card.-highlighted
      .fp-vertical-spacing.-s-lg
        .fp-vertical-spacing--line
          .fp-horizontal-spacing.-s-sm.-items-center
            .fp-horizontal-spacing--column
              i.fp-icon.fp-glyph-euro.-s16
            .fp-horizontal-spacing--column
              .fp-text.-text-semi-bold.-text-lg {{ t('exclusiveForm.pricing') }}
        .fp-vertical-spacing--line(v-if='club.model === \'FOUNDATION\'')
          .fp-horizontal-spacing--column
            .fp-vertical-spacing.-s-sm
              .fp-vertical-spacing--line
                label.fp-field {{ t('exclusiveForm.isAuction.label') }}
              .fp-vertical-spacing--line
                .fp-checkable-element
                  .fp-checkable-element--checkbox
                    input.fp-radio#is-not-auction-radio(type='radio' name='is-auction' v-model='exclusiveForm.isAuction' :value='false' :disabled='isUpdating' data-test='is-not-auction')
                  .fp-checkable-element--label
                    label(for='is-not-auction-radio') {{ t('exclusiveForm.isAuction.standardSaleLabel') }}
              .fp-vertical-spacing--line
                .fp-checkable-element
                  .fp-checkable-element--checkbox
                    input.fp-radio#is-auction-radio(type='radio' name='is-auction' v-model='exclusiveForm.isAuction' :value='true' :disabled='isUpdating' data-test='is-auction')
                  .fp-checkable-element--label
                    label(for='is-auction-radio') {{ t('exclusiveForm.isAuction.auctionLabel') }}
        .fp-vertical-spacing--line
          .fp-responsive-grid.-cols2.-cols1-up-md
            .fp-responsive-grid--column(v-if='!exclusiveForm.isAuction')
              label.fp-field
                | {{ t('exclusiveForm.stock') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetNumberField("stock")' :class='hasFieldChanged("stock") ? "-pointer" : "-color-shade-400"' data-test='reset-stock' :title='t(\'undo\')')
                input.fp-input-text(v-model='exclusiveForm.stock' data-test='stock' type='number' step='1' min='0' :class='{"-touched": isUpdating && hasFieldChanged("stock")}' required)
            .fp-responsive-grid--column(v-if='exclusiveForm.isAuction')
              label.fp-field
                | {{ t('exclusiveForm.auction') }}
                +fp-select
                  select(v-model='exclusiveForm.auctionId' name='auctionId' data-test='auction' required :disabled='isUpdating')
                    option(v-for='(auction, index) in auctions.value()' :key='index' :value='auction.id' v-text='auction.name')
            .fp-responsive-grid--column(v-if='exclusiveForm.isAuction && useLotNumbers')
              label.fp-field
                | {{ t('exclusiveForm.auctionLotNumber') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetAuctionLotNumber' :class='hasFieldChanged("auctionLotNumber") ? "-pointer" : "-color-shade-400"' data-test='reset-auction-lot-number' :title='t(\'undo\')')
                input.fp-input-text(v-model='exclusiveForm.auctionLotNumber' type='number' required step='1' min='0' :class='{"-touched": isUpdating && hasFieldChanged("auctionLotNumber")}' data-test='auction-lot-number')
        .fp-vertical-spacing--line(data-test='price')
          .fp-responsive-grid.-cols2.-cols1-up-md
            .fp-responsive-grid--column
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  label.fp-field(data-test='price-label')
                    | {{ t(`exclusiveForm.${exclusiveForm.isAuction ? 'startingPrice' : 'price'}`) }}
                    .fp-field--actions
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetNumberField("priceCoinsAmount")' :class='hasFieldChanged("priceCoinsAmount") ? "-pointer" : "-color-shade-400"' data-test='reset-price' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  ConverterVue(:fiat-only='!club.coinsEnabled' :coins-amount='exclusiveForm.priceCoinsAmount' :coin='club.coin' :touched='isUpdating && hasFieldChanged("priceCoinsAmount")' @update-coins-amount='(event) => updateCoinsAmount(event.value)' :disabled='limitedUpdate')
        .fp-vertical-spacing--line(v-if='club.model === \'FOUNDATION\' && [\'lou-rugby\', \'sport-sans-frontieres\'].includes(club.slug)' data-test='tax-deduction')
          .fp-responsive-grid.-cols2.-cols1-up-md
            .fp-responsive-grid--column
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  label.fp-field
                    | {{ t('exclusiveForm.taxDeduction') }}
                    .fp-field--actions
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetTaxDeduction' :class='hasFieldChanged("isTaxDeductible") || hasFieldChanged("nonDeductibleAmount") ? "-pointer" : "-color-shade-400"' data-test='reset-tax-deduction' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  .fp-checkable-element
                    .fp-checkable-element--checkbox
                      input.fp-checkbox#tax-deduction-checkbox(type='checkbox' name='tax-deduction' :class='{"-touched": isUpdating && hasFieldChanged("isTaxDeductible")}' v-model='exclusiveForm.isTaxDeductible' data-test='is-tax-deductible')
                    .fp-checkable-element--label
                      label(for='tax-deduction-checkbox') {{ t('exclusiveForm.isTaxDeductible') }}
                        .fp-text.-text-xs {{ t('exclusiveForm.isTaxDeductibleChecked') }}
        .fp-vertical-spacing--line(v-if='club.model === \'FOUNDATION\' && [\'lou-rugby\', \'sport-sans-frontieres\'].includes(club.slug) && exclusiveForm.isTaxDeductible' data-test='tax-deduction-amount')
          .fp-responsive-grid.-cols2.-cols1-up-md
            .fp-responsive-grid--column
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  label.fp-field
                    | {{ t('exclusiveForm.nonDeductibleAmount') }}
                    .fp-field--actions
                      i.fp-icon.fp-glyph-undo(v-if='isUpdating' @click='resetNonDeductibleAmount' :class='hasFieldChanged("nonDeductibleAmount") ? "-pointer" : "-color-shade-400"' data-test='reset-non-deductible-amount' :title='t(\'undo\')')
                .fp-vertical-spacing--line
                  ConverterVue(fiat-only :coins-amount='taxDeductionCoinsAmount' :coin='club.coin' :touched='isUpdating && hasFieldChanged("nonDeductibleAmount")' @update-coins-amount='(event) => updateNonDeductibleAmount(event.value)')
            .fp-responsive-grid--column
              .fp-alert.-info
                .fp-vertical-spacing.-s-sm
                  .fp-vertical-spacing--line
                    .fp-field {{ t('exclusiveForm.simulation.title') }}
                  .fp-vertical-spacing--line
                    .fp-vertical-spacing.-s-xs
                      .fp-vertical-spacing--line(v-if='exclusiveForm.isAuction')
                        .fp-text(data-test='final-price') {{ t('exclusiveForm.simulation.finalPrice') }} {{ priceSimulationText }}
                      .fp-vertical-spacing--line(v-if='exclusiveForm.isAuction')
                        input(type='range' v-model='priceSimulation' :min='priceSimulationMin' :max='priceSimulationMax' :step='priceSimulationStep' data-test='simulation-price')
                      .fp-vertical-spacing--line
                        .fp-text.-text-semi-bold(data-test='simulation-non-deductible') {{ t('exclusiveForm.simulation.nonDeductible') }} {{ priceSimulationNonDeductible.text }}
                        .fp-text.-text-xs {{ t('exclusiveForm.simulation.nonDeductibleTips') }}
