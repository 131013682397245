import router from '@/router/router';
import { PaymentPartnerFormUi } from '@/staff/primary/club/payment-partner-form/PaymentPartnerForm.ui';
import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { PaymentPartnerFormVue } from '@/staff/primary/club/payment-partner-form';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';

export default defineComponent({
  name: 'PaymentPartner',

  components: {
    PaymentPartnerFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const onConfirm = (paymentPartnerForm: PaymentPartnerFormUi): Promise<void> =>
      clubRepository
        .onboardPaymentPartner(props.clubSlug, paymentPartnerForm.email)
        .then(onboardingUrl => pageRedirecter.navigateTo(onboardingUrl))
        .catch(error => logger.error('Failed to onboard payment partner', error));

    const onGoBack = () => {
      router.push({ name: 'clubOverview', params: { clubSlug: props.clubSlug } });
    };

    return {
      onGoBack,
      onConfirm,
      t,
    };
  },
});
