PageVue
  .fp-vertical-spacing.-s-xl
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          button.fp-icon.fp-glyph-chevron-left.-s16.-pointer(data-test='payment-partner.back' @click='onGoBack')
        .fp-horizontal-spacing--column
          h2.fp-title {{ t('paymentPartnerForm.title') }}
    .fp-vertical-spacing--line
      PaymentPartnerFormVue#payment-partner-form(@confirm='onConfirm')
  template(v-slot:footer)
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'goBack\')' icon='chevron-left' secondary data-test='payment-partner.footer.back' @click='onGoBack')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(:text='t(\'paymentPartnerForm.onboard\')' type='submit' form='payment-partner-form' icon='arrow-square-in' data-test='payment-partner.submit')
