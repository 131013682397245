import { Club } from '@/staff/domain/club/Club';
import { Language } from '@/common/domain/Language';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { Optional } from '@/common/domain/Optional';
import { emptyTranslations, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { TranslatableField } from '@/common/domain/TranslatableField';
import { Translation } from '@/common/domain/Translation';
import { Email } from '@/staff/domain/Email';

export interface ClubFormUi {
  name: string;
  platformName: string;
  logoUrl: string;
  bannerUrl: string;
  contactEmail: string;
  recipientEmails: string[];
  presentationTranslations: TranslationUi[];
}

export const fromClub = (club: Club, recipientEmails: Email[], languages: Language[]): ClubFormUi => ({
  name: club.name,
  platformName: club.platformName,
  logoUrl: club.logoUrl,
  bannerUrl: club.bannerUrl,
  contactEmail: club.contactEmail,
  recipientEmails: recipientEmails,
  presentationTranslations: fromTranslations(languages, club.presentationTranslations!),
});

const toOptionalString = (formValue: string, domainValue: string): Optional<string> =>
  Optional.ofEmpty(formValue).filter(str => str.localeCompare(domainValue) !== 0);

const toOptionalArrayString = (formValue: string[], domainValue: string[]): Optional<string[]> =>
  Optional.ofUndefinable(formValue).filter(strArray => JSON.stringify(strArray) !== JSON.stringify(domainValue));

export const toClubToUpdate = (clubForm: ClubFormUi, club: Club, recipientEmails: Email[]): ClubToUpdate => ({
  slug: club.slug,
  name: toOptionalString(clubForm.name, club.name),
  platformName: toOptionalString(clubForm.platformName, club.platformName),
  logoUrl: toOptionalString(clubForm.logoUrl, club.logoUrl),
  bannerUrl: toOptionalString(clubForm.bannerUrl, club.bannerUrl),
  contactEmail: toOptionalString(clubForm.contactEmail, club.contactEmail),
  recipientEmails: toOptionalArrayString(clubForm.recipientEmails, recipientEmails),
  presentationTranslations: toUpdatedTranslations(club.presentationTranslations!, clubForm.presentationTranslations),
});

const toUpdatedTranslations = <T extends TranslatableField>(
  oldTranslations: Translation<T>[],
  uiTranslations: TranslationUi[]
): Optional<Translation<T>[]> =>
  JSON.stringify(uiTranslations).localeCompare(JSON.stringify(oldTranslations)) !== 0
    ? Optional.of(uiTranslations.filter(t => t.value).map(fromTranslationUi<T>))
    : Optional.empty();

export const emptyClubForm = (languages: string[]): ClubFormUi => ({
  name: '',
  platformName: '',
  logoUrl: '',
  bannerUrl: '',
  contactEmail: '',
  recipientEmails: [''],
  presentationTranslations: emptyTranslations(languages),
});

export const hasClubFormChanged = (clubForm: ClubFormUi, comparedClub: ClubFormUi) =>
  JSON.stringify(clubForm) !== JSON.stringify(comparedClub);

export type ClubTextFormInput = keyof Pick<ClubFormUi, 'name' | 'platformName' | 'logoUrl' | 'bannerUrl'>;
