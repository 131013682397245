import { computed, ComputedRef, defineComponent, inject, onMounted, onUnmounted, PropType, ref, toRaw, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { MarkdownEditorVue } from '@/common/primary/markdown/editor';
import { hasPostFormChanged, PostFormUi, PostTextFormInput } from '@/staff/primary/post/post-form/PostForm.ui';
import { globalWindowKey } from '@/common/domain/Window';
import { declareOnBeforeRouteLeave } from '@/common/primary/router/VueHooksDeclarator';
import { renderMarkdown } from '@/common/primary/markdown/MarkdownRenderer';
import { Language } from '@/common/domain/Language';
import { programRepositoryKey } from '@/staff/domain/program/ProgramRepository';
import { Media } from '@/common/domain/Media';
import { MediaType } from '@/common/domain/MediaType';
import { MultipleMediasInputVue } from '@/staff/primary/media-input/multiple-medias-input';
import { SingleImageInputVue } from '@/staff/primary/media-input/single-image-input';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { translationFor, TranslationUi } from '@/common/primary/Translation.ui';
import { fromProgram, ProgramSelectEntryUi } from '@/staff/primary/post/post-form/ProgramSelectEntry.ui';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { dragOver, dragStart, drop } from '@/staff/primary/club/club-form/DragDrop';
import { createDraftDescriptionSidebar } from '@/common/primary/sidebar/Sidebars';
import { sidebarBusKey } from '@/common/domain/sidebar/SidebarBus';
import { DraftDescriptionSidebarOptions } from '@/staff/primary/completion/draft-description-sidebar/DraftDescriptionSidebarOptions';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { DraftDescriptionRequest } from '@/staff/domain/completion/DraftDescriptionRequest';
import { postRepositoryKey } from '@/staff/domain/post/PostRepository';

const postNameForSlugMatcher = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;

export default defineComponent({
  name: 'PostForm',

  components: { MultipleMediasInputVue, MarkdownEditorVue, SingleImageInputVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    isUpdating: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    formValue: {
      type: Object as PropType<PostFormUi>,
      required: true,
    },
  },

  emits: ['confirm'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const postRepository = inject(postRepositoryKey)!;
    const programRepository = inject(programRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const sidebarBus = inject(sidebarBusKey)!;
    const globalWindow = inject(globalWindowKey)!;

    const postForm = ref<PostFormUi>(structuredClone(toRaw(props.formValue)));
    const isLoading = ref<boolean>(false);
    const askedCreate = ref<boolean>(false);
    const draftDescription = ref<string>('');
    const isTranslationLoading = ref<boolean>(false);
    const hiddenPreviews = ref<Record<string, boolean>>({});
    const previewVisibleFor = (translation: TranslationUi) => !hiddenPreviews.value[translation.language];
    const programsList = ref<ProgramSelectEntryUi[]>([{ name: t('postForm.program.none'), slug: undefined }]);
    const hasName = computed(() => postForm.value.nameTranslations[0].value.length > 0);
    const hasDescription = computed(() => postForm.value.descriptionTranslations[0].value.length > 0);

    const formatDescription = (description: string) => renderMarkdown(description) as string;

    const mainImage = computed((): Media | undefined => {
      if (postForm.value.imageUrl === '') {
        return undefined;
      }
      return {
        url: postForm.value.imageUrl,
        type: MediaType.IMAGE,
      };
    });

    const hasFormChanged = () => computed(() => hasPostFormChanged(postForm.value as PostFormUi, props.formValue));

    onMounted(async () => {
      globalWindow.onbeforeunload = () => {
        if (hasFormChanged().value && !askedCreate.value) {
          return t('confirmLeaving');
        }
      };
      await listPrograms();
    });

    watch(
      () => [props.formValue],
      () => {
        postForm.value = structuredClone(toRaw(props.formValue));
      }
    );

    const listPrograms = async () =>
      await programRepository.list(props.clubSlug).then(programs => programs.map(program => programsList.value.push(fromProgram(program))));

    onUnmounted(() => {
      globalWindow.onbeforeunload = () => {};
    });

    const routeLeaveGuard = () => {
      if (hasFormChanged().value && !askedCreate.value) {
        return globalWindow.confirm(t('confirmLeaving'));
      }
    };

    declareOnBeforeRouteLeave(routeLeaveGuard);

    const confirm = () => {
      if (postForm.value.descriptionTranslations.some(translation => !translation.value?.trim())) {
        globalWindow.alert(t('postForm.blankDescription'));

        return;
      }

      if (!props.isUpdating) {
        askedCreate.value = true;
      }
      emit('confirm', postForm.value);
    };

    const updateDraft = (description: string) => {
      draftDescription.value = description;
      replaceDescriptionWithDraft('fr');
    };

    const requestDraftDescription = (clubSlug: ClubSlug, request: DraftDescriptionRequest) =>
      postRepository.requestDraftDescription(clubSlug, request);

    const openDraftDescriptionSidebar = async () => {
      const modal = createDraftDescriptionSidebar();
      sidebarBus.open<DraftDescriptionSidebarOptions>({
        component: modal,
        isClosable: true,
        title: t('completion.draftDescriptionSidebar.title'),
        options: {
          clubSlug: props.clubSlug,
          name: translationFor(postForm.value.nameTranslations, 'fr'),
          requestDraftDescription,
          onDraftReceived: updateDraft,
        },
      });
    };

    const replaceDescriptionWithDraft = (language: Language) => {
      translationFor(postForm.value.descriptionTranslations, language).value = draftDescription.value;
    };

    const computeSlug = (name: string) => {
      if (props.isUpdating) {
        return;
      }

      postForm.value.slug =
        name &&
        name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .match(postNameForSlugMatcher)!
          .map(x => x.toLowerCase())
          .join('-');
    };

    const onNameFocusOut = (translation: TranslationUi) => {
      if (translation.language !== 'fr') {
        return;
      }

      computeSlug(translation.value);
    };

    const resetTextField = (field: PostTextFormInput) => {
      postForm.value[field] = props.formValue[field];
    };

    const resetProgram = () => {
      postForm.value.programSlug = props.formValue.programSlug;
    };

    const resetDateField = (field: 'date' | 'time') => {
      postForm.value.date[field] = props.formValue.date[field];
    };

    const resetNameTranslation = (language: Language) => {
      translationFor(postForm.value.nameTranslations, language).value = translationFor(props.formValue.nameTranslations, language).value;
    };

    const resetDescriptionTranslation = (language: Language) => {
      translationFor(postForm.value.descriptionTranslations, language).value = translationFor(
        props.formValue.descriptionTranslations,
        language
      ).value;
    };

    const hasFieldChanged = (field: keyof PostFormUi) => postForm.value[field] !== props.formValue[field];

    const hasDateFieldChanged = (field: keyof DateTimeInputUi) => postForm.value.date[field] !== props.formValue.date[field];

    const hasNameTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.nameTranslations, translation.language).value;

    const hasDescriptionTranslationChanged = (translation: TranslationUi) =>
      translation.value !== translationFor(props.formValue.descriptionTranslations, translation.language).value;

    const togglePreviewFor = (translation: TranslationUi) => {
      hiddenPreviews.value[translation.language] = !hiddenPreviews.value[translation.language];
    };

    const updateTranslation = (translation: TranslationUi, updatedDescription: string) => {
      translation.value = updatedDescription;
    };

    const updateImage = (media: Media) => (postForm.value.imageUrl = media.url);

    const updateMedias = (medias: Media[]) => (postForm.value.medias = medias);

    const resetImageUrl = () => {
      postForm.value.imageUrl = props.formValue.imageUrl;
    };

    const translateText = async (targetLanguage: string, translations: TranslationUi[], hasTranslation: ComputedRef<boolean>) => {
      if (hasTranslation.value && !isTranslationLoading.value) {
        isTranslationLoading.value = true;
        const frenchTranslation = translationFor(translations, 'fr');
        const translation = await clubRepository.translateText({
          text: frenchTranslation.value,
          sourceLanguage: 'fr',
          targetLanguage,
          clubSlug: props.clubSlug,
        });
        const index = translations.findIndex(translation => translation.language === targetLanguage);
        translations.splice(index, 1, {
          ...translations[index],
          value: translation,
        });
        isTranslationLoading.value = false;
      }
    };

    const translateName = (targetLanguage: string) => translateText(targetLanguage, postForm.value.nameTranslations, hasName);

    const translateDescription = (targetLanguage: string) =>
      translateText(targetLanguage, postForm.value.descriptionTranslations, hasDescription);

    const hasMediasChanged = () => JSON.stringify(postForm.value.medias) !== JSON.stringify(props.formValue.medias);

    const resetMedias = () => {
      postForm.value.medias = props.formValue.medias;
    };

    const onDragStart = (event: DragEvent) => dragStart(event, postForm.value);

    const onDragOver = async (event: DragEvent) => dragOver(event);

    const onDrop = async (event: DragEvent) => {
      drop<PostFormUi>(event).ifPresent(value => (postForm.value = value));
    };

    return {
      t,
      mainImage,
      confirm,
      isLoading,
      postForm,
      programsList,
      resetImageUrl,
      translateText,
      resetMedias,
      hasMediasChanged,
      updateImage,
      updateMedias,
      onDragStart,
      onDragOver,
      onDrop,
      onNameFocusOut,
      resetDateField,
      resetTextField,
      resetProgram,
      hasDescription,
      hasName,
      translateDescription,
      translateName,
      hasDateFieldChanged,
      hasFieldChanged,
      isTranslationLoading,
      routeLeaveGuard,
      togglePreviewFor,
      updateTranslation,
      previewVisibleFor,
      openDraftDescriptionSidebar,
      formatDescription,
      resetNameTranslation,
      hasNameTranslationChanged,
      resetDescriptionTranslation,
      hasDescriptionTranslationChanged,
    };
  },
});
