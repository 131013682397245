import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { exclusiveRepositoryKey } from '@/staff/domain/exclusive/ExclusiveRepository';
import {
  ExclusiveFormUi,
  fromExclusive,
  emptyExclusiveForm,
  toExclusiveToCreate,
} from '@/staff/primary/exclusive/exclusive-form/ExclusiveForm.ui';
import { ExclusiveFormVue } from '@/staff/primary/exclusive/exclusive-form';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { Loader } from '@/common/primary/loader/Loader';
import { PageVue } from '@/staff/primary/club-layout/page';
import { loggerKey } from '@/common/domain/Logger';

export default defineComponent({
  name: 'CreateExclusive',

  components: {
    ExclusiveFormVue,
    FairplayerButtonVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const alertBus = inject(alertBusKey)!;
    const logger = inject(loggerKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const exclusiveForm = ref(Loader.loading<ExclusiveFormUi>());
    const creating = ref(false);

    const initExclusiveToCreateFromExistingExclusive = async (fromQueryParameter: string): Promise<ExclusiveFormUi> => {
      const [clubSlug, exclusiveSlug] = fromQueryParameter.split('/');

      const exclusive = await exclusiveRepository.getBySlug(clubSlug, exclusiveSlug);

      return fromExclusive(exclusive, club.languages);
    };

    const initExclusiveToCreate = async (): Promise<ExclusiveFormUi> => {
      const fromQueryParameter = route.query.from;
      if (fromQueryParameter) {
        return await initExclusiveToCreateFromExistingExclusive(fromQueryParameter.toString());
      }

      return emptyExclusiveForm(club.languages);
    };

    onMounted(async () => {
      exclusiveForm.value.loaded(await initExclusiveToCreate());
    });

    const createExclusive = (exclusiveForm: ExclusiveFormUi): Promise<void> => {
      creating.value = true;

      return exclusiveRepository
        .create(toExclusiveToCreate(exclusiveForm, props.clubSlug))
        .then(() => goToCreatedExclusive(exclusiveForm))
        .catch(error => logger.error('Failed to create exclusive', error))
        .finally(() => (creating.value = false));
    };

    const goToCreatedExclusive = (exclusiveForm: ExclusiveFormUi) => {
      alertBus.alert('toasts.success.exclusive.creation', 'success');

      router.push({ name: 'updateExclusive', params: { clubSlug: props.clubSlug, exclusiveSlug: exclusiveForm.slug } });
    };

    const onGoBack = () => {
      router.push({ name: 'exclusives', params: { clubSlug: props.clubSlug } });
    };

    return {
      club,
      createExclusive,
      exclusiveForm,
      creating,
      onGoBack,
      t,
    };
  },
});
