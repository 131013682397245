import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Post } from '@/staff/domain/post/Post';
import { Url } from '@/common/domain/Url';
import { Language } from '@/common/domain/Language';
import { PostToCreate } from '@/staff/domain/post/PostToCreate';
import { PostToUpdate } from '@/staff/domain/post/PostToUpdate';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { emptyTranslations, fromTranslations, fromTranslationUi, TranslationUi } from '@/common/primary/Translation.ui';
import { PostName } from '@/staff/domain/post/PostName';
import { PostDescription } from '@/staff/domain/post/PostDescription';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { fromMediaUi } from '@/common/primary/Media.ui';
import { toDateToUpdate } from '../../toDateToUpdate';
import { toArrayToUpdate } from '@/staff/primary/toArrayToUpdate';
import { PostToUpdateDeletions } from '@/staff/domain/post/PostToUpdateDeletions';

export type PostTextFormInput = keyof Pick<PostFormUi, 'slug' | 'imageUrl'>;

export interface PostFormUi {
  slug: string;
  imageUrl: Url;
  date: DateTimeInputUi;
  programSlug?: string;
  medias: Media[];
  nameTranslations: TranslationUi[];
  descriptionTranslations: TranslationUi[];
}

export const toPostToCreate = (postForm: PostFormUi, clubSlug: ClubSlug): PostToCreate => ({
  clubSlug,
  slug: postForm.slug,
  imageUrl: postForm.imageUrl,
  date: DateTimeInputUi.toDate(postForm.date),
  programSlug: Optional.ofEmpty(postForm.programSlug),
  medias: postForm.medias,
  nameTranslations: postForm.nameTranslations.map(fromTranslationUi<PostName>),
  descriptionTranslations: postForm.descriptionTranslations.map(fromTranslationUi<PostDescription>),
});

export const fromPost = (post: Post, languages: Language[]): PostFormUi => ({
  slug: post.slug,
  imageUrl: post.imageUrl,
  date: new DateTimeInputUi(post.date),
  programSlug: post.programSlug.orElseUndefined(),
  medias: post.medias,
  nameTranslations: fromTranslations(languages, post.nameTranslations!),
  descriptionTranslations: fromTranslations(languages, post.descriptionTranslations!),
});

export const toPostToUpdate = (postForm: PostFormUi, post: Post, clubSlug: ClubSlug): PostToUpdate => ({
  clubSlug: clubSlug,
  currentSlug: post.slug,
  slug: toString(postForm, post, 'slug'),
  imageUrl: toString(postForm, post, 'imageUrl'),
  date: toDateToUpdate(postForm.date, post.date),
  programSlug: postForm.programSlug !== post.programSlug.orElseUndefined() ? Optional.of(postForm.programSlug!) : Optional.empty(),
  medias: toArrayToUpdate(post.medias, postForm.medias, fromMediaUi),
  nameTranslations: toArrayToUpdate(post.nameTranslations!, postForm.nameTranslations, fromTranslationUi),
  descriptionTranslations: toArrayToUpdate(post.descriptionTranslations!, postForm.descriptionTranslations, fromTranslationUi),
  deletions: toDeletions(postForm, post),
});

const toDeletions = (postForm: PostFormUi, post: Post): Optional<PostToUpdateDeletions> => {
  const removedProgram = post.programSlug.isPresent() && !postForm.programSlug;

  if (removedProgram) {
    return Optional.of({ program: true });
  }

  return Optional.empty();
};

type UpdatablePostStringField = keyof Pick<PostFormUi, 'slug' | 'imageUrl'>;

const toString = <T>(postForm: PostFormUi, post: Post, field: UpdatablePostStringField): Optional<T> =>
  postForm[field].localeCompare(post[field]) !== 0 ? Optional.of<T>(postForm[field] as T) : Optional.empty();

export const emptyPostForm = (languages: string[]): PostFormUi => ({
  slug: '',
  imageUrl: '',
  date: new DateTimeInputUi(new Date()),
  programSlug: undefined,
  medias: [],
  nameTranslations: emptyTranslations(languages),
  descriptionTranslations: emptyTranslations(languages),
});

export const hasPostFormChanged = (postForm: PostFormUi, comparedPost: PostFormUi) =>
  JSON.stringify(postForm) !== JSON.stringify(comparedPost);
