import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { Optional } from '@/common/domain/Optional';
import {
  fromGiveawayQuestion,
  GiveawayQuestionFormUi,
  toGiveawayQuestionToCreate,
  toGiveawayQuestionToUpdate,
} from '@/staff/primary/giveaway/GiveawayQuestionForm.ui';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { DateTimeInputUi } from '@/common/primary/date/DateTimeInput.ui';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { GiveawayQuestionToUpdate } from '@/staff/domain/club/giveaway/GiveawayQuestionToUpdate';
import { GiveawayQuestion } from '@/staff/domain/club/giveaway/GiveawayQuestion';
import { fromPartner, nonePartner, PartnerUi } from '@/staff/primary/giveaway/giveaways-table/Partner.ui';
import { ComposerTranslation } from 'vue-i18n';
import { Url } from '@/common/domain/Url';
import { toDateToUpdate } from '@/staff/primary/toDateToUpdate';

export interface GiveawayFormUi {
  clubSlug: string;
  startDate: DateTimeInputUi;
  endDate: DateTimeInputUi;
  url: string;
  questions: GiveawayQuestionFormUi[];
  partner: PartnerUi;
}

export const fromGiveaway = (giveaway: Giveaway, clubSlug: ClubSlug, t: ComposerTranslation): GiveawayFormUi => ({
  clubSlug,
  startDate: new DateTimeInputUi(giveaway.startDate),
  endDate: new DateTimeInputUi(giveaway.endDate),
  url: giveaway.url,
  questions: giveaway.questions.map(fromGiveawayQuestion),
  partner: giveaway.partner.map(fromPartner).orElse(nonePartner(t)),
});

export const toGiveawayToCreate = (giveawayForm: GiveawayFormUi): GiveawayToCreate => ({
  startDate: DateTimeInputUi.toDate(giveawayForm.startDate),
  endDate: DateTimeInputUi.toDate(giveawayForm.endDate),
  url: giveawayForm.url,
  questions: giveawayForm.questions.map(toGiveawayQuestionToCreate),
  partner: Optional.ofEmpty(giveawayForm.partner.slug),
});

const stringify = (form: GiveawayFormUi) => JSON.stringify(form, (key, value) => (key === 'randomKey' ? undefined : value));

export const hasGiveawayFormChanged = (giveawayForm: GiveawayFormUi, comparedGiveaway: GiveawayFormUi) =>
  stringify(giveawayForm) !== stringify(comparedGiveaway);

const toUrlToUpdate = (giveawayFormUi: GiveawayFormUi, giveaway: Giveaway): Optional<string> =>
  Optional.ofEmpty(giveawayFormUi.url).filter(url => url.localeCompare(giveaway.url) !== 0);

const toPartnerToUpdate = (giveawayFormUi: GiveawayFormUi, giveaway: Giveaway): Optional<string> =>
  Optional.ofEmpty(giveawayFormUi.partner.slug).filter(
    partner => partner !== giveaway.partner.map(partner => partner.slug).orElseUndefined()
  );

const giveawayAnswerFieldsToCompare = ['text'];

const giveawayQuestionFieldsToCompare = [
  'preTitle',
  'value',
  'title',
  'answers',
  'rightAnswer',
  'required',
  'quizMode',
  'multiline',
  ...giveawayAnswerFieldsToCompare,
];
const questionsToString = (questions: GiveawayQuestionToUpdate[] | GiveawayQuestion[]): string =>
  JSON.stringify(questions, giveawayQuestionFieldsToCompare);

const toQuestionsToUpdate = (giveawayFormUi: GiveawayFormUi, giveaway: Giveaway): Optional<GiveawayQuestionToUpdate[]> =>
  Optional.ofUndefinable(giveawayFormUi.questions)
    .map(questions => questions.map(toGiveawayQuestionToUpdate))
    .filter(questions => questionsToString(questions) !== questionsToString(giveaway.questions));

export const toGiveawayToUpdate = (giveawayForm: GiveawayFormUi, giveaway: Giveaway): GiveawayToUpdate => ({
  id: giveaway.id,
  clubSlug: giveawayForm.clubSlug,
  startDate: toDateToUpdate(giveawayForm.startDate, giveaway.startDate),
  endDate: toDateToUpdate(giveawayForm.endDate, giveaway.endDate),
  url: toUrlToUpdate(giveawayForm, giveaway),
  questions: toQuestionsToUpdate(giveawayForm, giveaway),
  partner: toPartnerToUpdate(giveawayForm, giveaway),
});

export const emptyGiveawayForm = (clubSlug: ClubSlug, clubUrl: Url, t: ComposerTranslation): GiveawayFormUi => {
  const currentDate = new Date();
  const tomorrowDate = new Date(new Date().setDate(currentDate.getDate() + 1));

  return {
    clubSlug,
    startDate: new DateTimeInputUi(currentDate),
    endDate: new DateTimeInputUi(tomorrowDate),
    url: `${clubUrl}/giveaway`,
    partner: nonePartner(t),
    questions: [],
  };
};
