import { defineAsyncComponent, markRaw } from 'vue';

export const giveawayPreviewSidebar = () =>
  markRaw(defineAsyncComponent(() => import('@/staff/primary/giveaway/giveaway-form/giveaway-preview-sidebar/GiveawayPreviewSidebar.vue')));

export const clubPreviewSidebar = () =>
  markRaw(defineAsyncComponent(() => import('@/staff/primary/club/club-preview-sidebar/ClubPreviewSidebar.vue')));

export const createDraftDescriptionSidebar = () =>
  markRaw(defineAsyncComponent(() => import('@/staff/primary/completion/draft-description-sidebar/DraftDescriptionSidebar.vue')));
