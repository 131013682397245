import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { fromGiveaway, GiveawayFormUi, toGiveawayToUpdate } from '@/staff/primary/giveaway/GiveawayForm.ui';
import { alertBusKey } from '@/common/domain/alert/AlertBus';
import { useRouter } from 'vue-router';
import { GiveawayFormVue } from '@/staff/primary/giveaway/giveaway-form';
import { loggerKey } from '@/common/domain/Logger';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { Loader } from '@/common/primary/loader/Loader';
import { isGiveawayToUpdateEmpty } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { GiveawayStatus } from '@/staff/domain/club/giveaway/GiveawayStatus';
import { PageVue } from '@/staff/primary/club-layout/page';
import { giveawayParticipationRepositoryKey } from '@/staff/domain/club/giveaway/GiveawayRepository';

export default defineComponent({
  name: 'UpdateGiveaway',

  components: {
    FairplayerButtonVue,
    GiveawayFormVue,
    PageVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
    giveawayId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const alertBus = inject(alertBusKey)!;
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const giveawayParticipationRepository = inject(giveawayParticipationRepositoryKey)!;
    const club = clubRepository.getCurrentClub();
    const giveaway = ref<Giveaway>();
    const giveawayForm = ref(Loader.loading<GiveawayFormUi>());
    const hasParticipations = ref(false);
    const formValid = ref(true);
    const updating = ref(false);

    onMounted(() => {
      getNumberOfParticipations();
      getGiveaway();
    });

    const setFormValid = (isFormValid: boolean) => {
      formValid.value = isFormValid;
    };

    const getNumberOfParticipations = () => {
      giveawayParticipationRepository.listGiveawayParticipations(props.clubSlug, props.giveawayId).then(retrievedParticipations => {
        hasParticipations.value = retrievedParticipations.length > 0;
      });
    };

    const getGiveaway = () => {
      clubRepository
        .getGiveaway(props.clubSlug, props.giveawayId)
        .then(giveaway => setFormValue(giveaway))
        .catch(error => logger.error('Failed to retrieve giveaways', error));
    };

    const onGoBack = async (): Promise<void> => {
      await router.push({ name: 'giveaways', params: { clubSlug: props.clubSlug } });
    };

    const onConfirmUpdate = (updatedGiveawayForm: GiveawayFormUi): Promise<void> => {
      const giveawayToUpdate = toGiveawayToUpdate(updatedGiveawayForm, giveaway.value!);
      if (isGiveawayToUpdateEmpty(giveawayToUpdate)) {
        return Promise.resolve();
      }

      updating.value = true;

      return clubRepository
        .updateGiveaway(giveawayToUpdate)
        .then(giveaway => {
          alertBus.alert('toasts.success.giveaway.update', 'success');
          setFormValue(giveaway);
        })
        .catch(error => logger.error('Failed to update giveaway', error))
        .finally(() => (updating.value = false));
    };

    const setFormValue = (retrievedGiveaway: Giveaway) => {
      giveaway.value = retrievedGiveaway;
      giveawayForm.value.loaded(fromGiveaway(retrievedGiveaway, club.slug, t));
    };

    const limitedUpdate = computed(() => giveaway.value?.status === GiveawayStatus.ACTIVE && hasParticipations.value);

    return {
      club,
      giveawayForm,
      onConfirmUpdate,
      onGoBack,
      limitedUpdate,
      formValid,
      updating,
      setFormValid,
      t,
    };
  },
});
