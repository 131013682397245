import { Optional } from '@/common/domain/Optional';
import { Club } from '@/staff/domain/club/Club';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubSummary } from '@/staff/domain/club/ClubSummary';
import { InjectionKey } from 'vue';
import { CoinsGifts } from '@/staff/domain/club/CoinsGifts';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { Coin } from '@/common/domain/token/Coin';
import { FansStatistics } from '@/staff/domain/club/FansStatistics';
import { CoinsHoldings } from '@/staff/domain/club/CoinsHoldings';
import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import { CoinsGiftToCreate } from '@/staff/domain/club/CoinsGiftToCreate';
import { ClubToUpdate } from '@/staff/domain/club/ClubToUpdate';
import { TranslationRequest } from '@/staff/domain/club/TranslationRequest';
import { Email } from '@/staff/domain/Email';

export const clubRepositoryKey: InjectionKey<ClubRepository> = Symbol();

export interface ClubRepository {
  list(): Promise<ClubSummary[]>;
  retrieveClub(): Promise<Club>;
  findSelectedSlug(): Optional<ClubSlug>;
  clearSelectedClub(): void;
  getCurrentSlug(): Optional<ClubSlug>;
  findCurrentClub(): Optional<Club>;
  getCurrentClub(): Club;
  setCurrentClub(club: Club): void;
  update(clubToUpdate: ClubToUpdate): Promise<Club>;
  getRecipientEmails(clubSlug: ClubSlug): Promise<Email[]>;
  getCoinsGifts(clubSlug: ClubSlug, coin: Coin): Promise<CoinsGifts>;
  createCoinsGift(clubSlug: ClubSlug, coinGiftToCreate: CoinsGiftToCreate): Promise<void>;
  getCoinsHoldings(clubSlug: ClubSlug, coin: Coin): Promise<CoinsHoldings>;
  onboardPaymentPartner(clubSlug: ClubSlug, email: Email): Promise<string>;
  createGiveaway(clubSlug: ClubSlug, giveawayToCreate: GiveawayToCreate): Promise<void>;
  getGiveaways(clubSlug: ClubSlug): Promise<Giveaway[]>;
  getGiveaway(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<Giveaway>;
  updateGiveaway(giveawayToUpdate: GiveawayToUpdate): Promise<Giveaway>;
  closeGiveaway(closureToCreate: GiveawayClosureToCreate): Promise<void>;
  getFansStatistics(clubSlug: ClubSlug): Promise<FansStatistics>;
  translateText(translationRequest: TranslationRequest): Promise<string>;
}
